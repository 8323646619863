import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import WpLink from '../../elements/wpLink'
import { breakpoint, getColor } from '../../shared/theme'

const Button = styled.span`
	display: inline-block;
	position: relative;
	transition: all 200ms ${(prop) => prop.theme.ease};
	color: ${getColor('orange')};

	a {
		color: inherit;
		display: block;
		position: relative;
		padding-bottom: 16px;

		@media ${breakpoint('desktop')} {
			padding-bottom: 36px;
		}
	}

	&:hover {
		color: ${getColor('orange')};
	}
`

const Text = styled.h6`
	color: inherit;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
`

const Arrow = styled.div`
	position: absolute;
	height: 2px;
	background: ${getColor('orange')};
	width: 36px;
	bottom: 0;
	left: 0;
	transition: background 200ms ${(prop) => prop.theme.ease};

	${Button}:hover & {
		background: ${getColor('orange')};

		i {
			opacity: 1;
			transform: translate(0%, -50%) rotate(-45deg);
		}
	}

	i {
		position: absolute;
		right: 0;
		top: 50%;
		height: 12px;
		width: 12px;
		transform: translate(-10%, -50%) rotate(-45deg);
		z-index: 10;
		opacity: 0;
		transition: all 200ms ${(prop) => prop.theme.ease};

		&::before,
		&::after {
			content: '';
			position: absolute;
			height: 2px;
			width: 100%;
			background: ${getColor('orange')};
		}

		&::before {
			right: 0;
			bottom: -2px;
			transform-origin: top right;
			transform: rotate(90deg);
		}

		&::after {
			right: 0;
			bottom: 0;
		}
	}
`

const LinkButtonAlt = ({ path, title, target, colorTheme }) => {
	return (
		<Button colorTheme={colorTheme}>
			<WpLink uri={path} title={title}>
				<Text>{title}</Text>

				<Arrow colorTheme={colorTheme}>
					<i></i>
				</Arrow>
			</WpLink>
		</Button>
	)
}

LinkButtonAlt.propTypes = {
	title: PropTypes.string,
	path: PropTypes.string,
	colorTheme: PropTypes.string,
}

LinkButtonAlt.defaultProps = {
	title: '',
	path: '#',
	colorTheme: 'light',
}

export default LinkButtonAlt
